import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  typography: {
    fontFamily: [
      'Poppins',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    fontWeightBold: 700,
    fontWeightMedium: 500,
    fontWeightRegular: 400,
    fontWeightLight: 300,
  },
});

theme.typography.h1 = {
  ...theme.typography.h1,

  fontWeight: 'bold',
  fontSize: '4.25rem',

  [theme.breakpoints.down('sm')]: {
    fontSize: '3rem',
  },
}

theme.typography.h2 = {
  ...theme.typography.h2,

  fontWeight: 'bold',
  fontSize: '3rem',
}

theme.typography.h3 = {
  ...theme.typography.h3,

  fontWeight: 'bold',
}

theme.typography.h4 = {
  ...theme.typography.h4,

  fontWeight: 'bold',
}

export default theme;