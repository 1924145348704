/* eslint-disable jsx-a11y/img-redundant-alt */
import { useEffect, useRef, useState } from 'react';
// import { NavLink } from 'react-router-dom';

import { Typography, Box, Paper } from '@mui/material';
import ReactPlayer from 'react-player';

import Section from './Section';
import Footer from './Footer';

function isElementInViewport(el) {
  if (!el) return false;
  const {top, bottom} = el.getBoundingClientRect();
  return (top >= 0 && top <= window.innerHeight) || (bottom >= 0 && bottom <= window.innerHeight);
}

export default function App() {
  const panoVideoRef = useRef();
  const conteVideoRef = useRef();
  const productphotoVideoRef = useRef();
  
  const [panoVideoPlaying, setPanoVideoPlaying] = useState(false);
  const [conteVideoPlaying, setConteVideoPlaying] = useState(false);
  const [productphotoVideoPlaying, setProductphotoVideoPlaying] = useState(false);

  useEffect(() => {
    const handler = () => {
      if (panoVideoPlaying !== isElementInViewport(panoVideoRef.current)) {
        setPanoVideoPlaying(!panoVideoPlaying);
      }
      if (conteVideoPlaying !== isElementInViewport(conteVideoRef.current)) {
        setConteVideoPlaying(!conteVideoPlaying);
      }
      if (productphotoVideoPlaying !== isElementInViewport(productphotoVideoRef.current)) {
        setProductphotoVideoPlaying(!productphotoVideoPlaying);
      }
    };

    window.addEventListener('DOMContentLoaded', handler, false);
    window.addEventListener('load', handler, false);
    window.addEventListener('scroll', handler, false);
    window.addEventListener('resize', handler, false);
  }, [panoVideoPlaying, conteVideoPlaying, productphotoVideoPlaying]);

  return (
    <>
      <Section id="about">
        <Box display="flex" flexDirection="column" alignItems="center">
          <Typography align="center" variant="h6">
            hello, we are
          </Typography>
          <Box>
            <img src="/appreel.svg" height={40} alt="Appreel" />
          </Box>
        </Box>
        <Box>
          <Typography align="center" variant="h1" gutterBottom>
            We empower creative minds by crafting creative apps
          </Typography>
          <Typography align="center" variant="h5" color="GrayText">
            As a mobile development company, we specialize in building enduring and top-notch digital tools for creators.
          </Typography>
        </Box>
        <Box>
          <Typography align="center" variant="h4">
            &nbsp;
          </Typography>
        </Box>
      </Section>
      <Section sx={{backgroundColor: '#0c0d10', color: '#ffffff'}}>
        <Box />
        <Box display="flex" alignItems="center" sx={{flexDirection: {xs: 'column', sm: 'row'}}}>
          <Box>
            <Box display="flex" alignItems="center" mb={3}>
              <Paper style={{width: 64, height: 64, borderRadius: 16, marginRight: 16, overflow: 'hidden'}} elevation={5}>
                <img src="/pano-logo.webp" alt="PANO" width={64} height={64} />
              </Paper>
              <Typography variant="h2" component="span">
                PANO
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="h4">
                Seamless carousel posts for Instagram
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="h5">
                Create stunning panoramic posts and stories effortlessly with PANO. Select your photos and videos and layer them across multiple frames for a seamless Instagram layout.
              </Typography>
            </Box>
            <Box mb={3}>
              <a href="https://apps.apple.com/us/app/id1433880640" target="_blank" rel="noopener noreferrer">
                <img src="/appstore.svg" width={164} height={55} alt="App Store" />
              </a>
            </Box>
            {/* <Box display="flex" alignItems="center">
              <Link component={NavLink} to="/pano/terms" variant="h6" color="inherit" sx={{marginRight: 3}}>
                Terms &amp; Conditions
              </Link>
              <Link component={NavLink} to="/pano/privacy" variant="h6" color="inherit">
                Privacy Policy
              </Link>
            </Box> */}
          </Box>
          <Box
            width={296}
            height={640}
            sx={{
              borderRadius: 10,
              overflow: 'hidden',
              flex: 'none',
              marginLeft: {xs: 0, sm: 10},
              marginTop: {xs: 3, sm: 0},
              transform: 'translateZ(0)',
            }}
            ref={panoVideoRef}
          >
            <ReactPlayer width={296} height={640} muted playsinline playing={panoVideoPlaying} loop url="pano.mp4" />
          </Box>
        </Box>
        <Box />
      </Section>
      <Section>
        <Box />
        <Box display="flex" alignItems="center" sx={{flexDirection: {xs: 'column', sm: 'row'}}}>
          <Box>
            <Box display="flex" alignItems="center" mb={3}>
              <Paper style={{width: 64, height: 64, borderRadius: 16, marginRight: 16, overflow: 'hidden'}} elevation={5}>
                <img src="/conte-logo.webp" alt="CONTE" width={64} height={64} />
              </Paper>
              
              <Typography variant="h2" component="span">
                Conte
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="h4">
                Social content maker for business
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="h5">
                Create professional-looking Ads, Posters, Posts, and Stories for Instagram, TikTok, and other social networks in minutes.
              </Typography>
            </Box>
            <Box mb={3}>
              <a href="https://apps.apple.com/us/app/id1333505670" target="_blank" rel="noopener noreferrer">
                <img src="/appstore.svg" width={164} height={55} alt="App Store" />
              </a>
            </Box>
            {/* <Box display="flex" alignItems="center">
              <Link component={NavLink} to="/conte/terms" variant="h6" color="inherit" sx={{marginRight: 3}}>
                Terms &amp; Conditions
              </Link>
              <Link component={NavLink} to="/conte/privacy" variant="h6" color="inherit">
                Privacy Policy
              </Link>
            </Box> */}
          </Box>
          <Box
            width={296}
            height={640}
            sx={{
              borderRadius: 10,
              overflow: 'hidden',
              flex: 'none',
              marginLeft: {xs: 0, sm: 10},
              marginTop: {xs: 3, sm: 0},
              transform: 'translateZ(0)',
            }}
            ref={conteVideoRef}
          >
            <ReactPlayer width={296} height={640} muted playsinline playing={conteVideoPlaying} loop url="conte.mp4" />
          </Box>
        </Box>
        <Box />
      </Section>
      <Section sx={{backgroundColor: '#FF8C4B', color: '#ffffff'}}>
        <Box />
        <Box display="flex" alignItems="center" sx={{flexDirection: {xs: 'column', sm: 'row'}}}>
          <Box>
            <Box display="flex" alignItems="center" mb={3}>
              <Paper style={{width: 64, height: 64, borderRadius: 16, marginRight: 16, overflow: 'hidden', flex: 'none'}} elevation={5}>
                <img src="/productphoto-logo.webp" alt="Product Photo" width={64} height={64} />
              </Paper>
              <Typography variant="h2" component="span">
                Product Photo
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="h4">
                Design Studio<br />for E-Commerce right on your iPhone
              </Typography>
            </Box>
            <Box mb={3}>
              <Typography variant="h5">
                Take a photo of your product, remove the background in seconds, add outline and shadow, choose one of the studio backdrops, and get the result. Perfectly fits for Amazon, Shopify, Etsy, Depop and other platforms.
              </Typography>
            </Box>
            <Box mb={3}>
              <a href="https://apps.apple.com/us/app/id1614491681" target="_blank" rel="noopener noreferrer">
                <img src="/appstore.svg" width={164} height={55} alt="App Store" />
              </a>
            </Box>
            {/* <Box display="flex" alignItems="center">
              <Link component={NavLink} to="/product-photo/terms" variant="h6" color="inherit" sx={{marginRight: 3}}>
                Terms &amp; Conditions
              </Link>
              <Link component={NavLink} to="/product-photo/privacy" variant="h6" color="inherit">
                Privacy Policy
              </Link>
            </Box> */}
          </Box>
          <Box
            width={296}
            height={640}
            sx={{
              borderRadius: 10,
              overflow: 'hidden',
              flex: 'none',
              marginLeft: {xs: 0, sm: 10},
              marginTop: {xs: 3, sm: 0},
              transform: 'translateZ(0)',
            }}
            ref={productphotoVideoRef}
          >
            <ReactPlayer width={296} height={640} muted playsinline playing={productphotoVideoPlaying} loop url="productphoto.mp4" />
          </Box>
        </Box>
        <Box />
      </Section>

      <Footer />
    </>
  );
}
