import { Container, Box } from '@mui/material';

export default function Section({children, ...props}) {
  return (
    <Box {...props}>
      <Container maxWidth="md">
        <Box py={3} sx={{minHeight: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center'}}>
          {children}
        </Box>
      </Container>
    </Box>
  );
}
