import { Typography, Box, Grid, Container, Link } from '@mui/material';
import { NavLink } from 'react-router-dom';

export default function Footer() {
  return (
    <Container maxWidth="md">
      <Box py={10}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography variant="h5" gutterBottom>
              Appreel Inc
            </Typography>
            <Typography>
              30 N GOULD ST, STE R<br/>
              SHERIDAN, WY 82801<br />
              US
            </Typography>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="h5" gutterBottom>
              Products
            </Typography>
            <Link href="https://apps.apple.com/us/app/id1433880640" target="_blank" variant="h6" color="#9861A5" gutterBottom>
              PANO
            </Link>
            <br />
            <Link href="https://apps.apple.com/us/app/id1333505670" target="_blank" variant="h6" color="#9861A5" gutterBottom>
              Conte
            </Link>
            <br />
            <Link href="https://apps.apple.com/us/app/id1614491681" target="_blank" variant="h6" color="#9861A5" gutterBottom>
              Product Photo
            </Link>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Typography variant="h5" gutterBottom>
              Company
            </Typography>
            <Link component={NavLink} to="/" variant="h6" color="#9861A5" gutterBottom>
              About
            </Link>
            <br />
            <Link href="https://www.instagram.com/appreel.co/" target="_blank" variant="h6" color="#9861A5" gutterBottom>
              Instagram
            </Link>
            <br />
            <Link href="mailto:hey@appreel.co" target="_blank" variant="h6" color="#9861A5" gutterBottom>
              E-Mail
            </Link>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
